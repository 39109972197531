<template>
  <div class="app-container">
    <button class="sidebar-toggle" @click="toggleSidebar">☰</button>
    <aside :class="['sidebar', { 'sidebar-closed': isSidebarClosed }]">
      <div class="sidebar-content">
        <!-- Second Section -->
        <div class="sidebar-section">
          <div class="sidebar-title">Welk type data moet Squadra LLM gebruiken?</div>
          <div class="sidebar-option">
            <input type="radio" id="option2-1" name="section2" value="false" v-model="section2Selected">
            <label for="option2-1">Squadra data</label>
          </div>
          <div class="sidebar-option">
            <input type="radio" id="option2-2" name="section2" value="true" v-model="section2Selected">
            <label for="option2-2">Squadra data + ChatGPT</label>
          </div>
        </div>

        <div class="sidebar-section">
          <button @click="removeHistory" class="remove-button">Verwijder chat geschiedenis</button>
        </div>
        
        <div class="sidebar-section">
          <button @click="goToHome" class="home-button">Home</button>
        </div>
        
        <div class="sidebar-section">
          <button @click="logout" class="logout-button">Logout</button>
        </div>
      </div>
    </aside>
    <main class="main-content">
      <div class="header">
        <h1 class="page-title clickable" @click="goToHome" title="Terug naar Home">Squadra LLM</h1>
        <img :src="chatbotLogo" class="message-logo" />
      </div>
      <div class="chat-container">
        <div class="messages">
        <message-component
          v-for="message in messages"
          :key="message.id"
          :message="message"
          :isUser="message.role === 'user'"
          class="message-content"
          @nextQuestion="handleNextQuestion"
          @changeAnswer="handleChangeAnswer"
        ></message-component>
        </div>
        <div class="input-area">
          <textarea v-model="userInput" 
                    @keyup.enter="sendMessage"
                    :placeholder="placeholderText"
                    :disabled="isWaitingForResponse"></textarea>
          <button @click="sendMessage" class="send-button">></button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MessageComponent from './MessageComponent.vue'

export default {
  updated() {
    this.scrollToBottom();
  },
  computed: {
  placeholderText() {
    return this.isWaitingForResponse 
      ? "Wacht op antwoord. Klik daarna op 'Vervolgvraag' of 'Nieuwe vraag' om verder te chatten..." 
      : "Vraag Squadra LLM...";
    }
  },
  data() {
    return {
      messages: [],
      history: [],
      userInput: '',
      follow_up: false,
      isSidebarClosed: false,
      userLogo: require('@/assets/user.png'),
      chatbotLogo: require('@/assets/chatbot.png'),
      section1Selected: 'true', // Default selection for the first section
      section2Selected: 'false', // Default selection for the second section
      section3Selected: 'false', // Default selection for the third section
      isWaitingForResponse: false,
      chat_option: 'next',
      initialQueryProcessed: false
    };
  },
  components: { MessageComponent },
  mounted() {
    // Check if there's an initial query from HomePage
    const query = this.$route.query.query;
    const initialChat = this.$route.query.initialChat;
    
    if (query && initialChat === 'true' && !this.initialQueryProcessed) {
      this.userInput = query;
      this.initialQueryProcessed = true;
      // Use setTimeout to ensure the component is fully mounted
      setTimeout(() => {
        this.sendMessage();
      }, 300);
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarClosed = !this.isSidebarClosed;
    },
    async goToHome() {
      await this.removeHistory();
      await this.removeSession();
      this.$router.push('/home');
    },
    sendMessage() {
      if (this.userInput.trim() && !this.isWaitingForResponse) {
        const newMessage = {
          content: this.userInput,
          role: 'user',
          docs: []
        };
        this.messages.push(newMessage);
        this.history.push(newMessage);
        this.getChatbotResponse();
      }
    },
    async getChatbotResponse() {
      const url = `/api/ask`;
      const headers = {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${process.env.VUE_APP_BEARER_TOKEN}` // Use BEARER_TOKEN from .env
      };
      const data = {
        "user_question": this.userInput.trim(),
        "show_documents": this.section1Selected === "true",
        "use_chatgpt": this.section2Selected === "true",
        "add_confidence_score": this.section3Selected === "true",
        "use_history": this.follow_up,
        "token": this.$cookies.get('auth_token')
      }
      this.userInput = '';
      try {
        this.isWaitingForResponse = true;
        const response = await this.$axios.post(url, data, { headers });
        // TODO: load json data: JSON.parse , and then put links as button
        // const data = JSON.parse(response);
        const newMessage = {
          content: response.data.result,
          role: 'assistant',
          docs: response.data.docs,
          follow_up: true
        }
        this.messages.push(newMessage);
        this.history.push(newMessage);
      }
      catch (error) {
        const newMessage = {
          content: 'Er heeft zich een error voorgedaan bij het genereren van een antwoord. Als dit zich vaker voordoet, contact admin.',
          role: 'assistant',
          docs: [],
          follow_up: false
        }
        this.messages.push(newMessage);
        this.history = [];
        this.follow_up = false;
      }
    },
    scrollToBottom() {
      const container = this.$el.querySelector('.messages');
      container.scrollTop = container.scrollHeight;
    },
    handleNextQuestion() {
      this.follow_up = true;
      this.messages.push({
          id: this.messages.length + 1,
          content: "Stel je vervolgvraag",
          docs: [],
          role: 'assistant',
          follow_up: false
      });
      this.isWaitingForResponse = false;
      this.chat_option = 'next';
      this.follow_up = true;
    },
    handleChangeAnswer() {
      this.messages.push({
          id: this.messages.length + 1,
          content: "Stel je nieuwe vraag",
          docs: [],
          role: 'assistant',
          follow_up: false
        });
        this.chat_option = 'change';
        this.isWaitingForResponse = false;
        this.history = [];
        this.follow_up = false;
    },
    async logout() {
      await this.removeHistory();
      await this.removeSession();
      this.$cookies.remove('auth_token');
      this.$router.push('/login'); // Redirect to content page on success
    },
    async removeHistory() {
      this.messages = [];
      this.history = [];
      this.follow_up = false;
      this.isWaitingForResponse = false;
    },
    async removeSession() {
      const url = `/api/logout`;
      const headers = {
        "Authorization": `Bearer ${process.env.VUE_APP_BEARER_TOKEN}` // Use BEARER_TOKEN from .env
      };
      const data = { session_id: this.$cookies.get("auth_token") };
      const response = await this.$axios.post(url, data, { headers });
      console.log(response.data.success);
    }
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Tahoma', sans-serif;
}

.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #f2f2f0;
}

.sidebar-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 5;
  background: none;
  border: none;
  padding: 0;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}

.sidebar {
  background-color: #148cb3;
  color: #fff;
  width: 300px;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  transition: transform 0.3s ease;
}

.sidebar-closed {
  transform: translateX(-300px);
}

.sidebar-content {
  padding: 60px 25px 25px;
  margin-top: 20px;
}

.sidebar-section {
  margin-bottom: 30px;
}

.sidebar-option,
.sidebar-title {
  margin-bottom: 10px;
}

.sidebar-option input[type="radio"] {
  margin-right: 10px;
}

.sidebar-option label {
  cursor: pointer;
}

.main-content {
  flex-grow: 1;
  margin-left: 300px;
  background-color: #f2f2f0;
  color: #000000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #f2f2f0;
  color: #343541;
  display: flex;
  /* align-items: center; */
  /* margin-left: 5px; */
  padding: 10px;
}

h1 {
  margin-left: 15px;
}

.page-title {
  font-size: 1.2em;
  margin-right: auto;
}

.message-logo {
  height: 50px;
  margin-left: auto;
}

.chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 75%;
  margin-left: 12.5%;
  margin-bottom: 0;
}

.message-content {
  white-space: pre-wrap; /* This will keep newlines and wrap text as needed */
}


/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent; /* Make the track background transparent */
  width: 2px; /* Thin track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(80, 80, 80);;
  border-radius: 2px;
}

.messages {
  height: calc(100vh - 300px);
  padding-bottom: 10px; /* Space for input area */
  position: relative;
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-color: rgb(80, 80, 80);
  scrollbar-width: 2px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 10px;
  margin-bottom: 30px;
}

.input-area {
  display: flex;
  background-color: #494b55;
  padding: 15px;
  border: 2px solid #063970;
  border-radius: 20px;
  width: 100%;
}

.input-area textarea {
  flex-grow: 1;
  background-color: #494b55;;
  font-size: 16px;
  color: white;
  border: none;
  padding: 10px;
  resize: none;
  word-break: break-word;
  scroll-behavior: smooth;
  scrollbar-color: rgb(80, 80, 80);
  scrollbar-width: 2px;
  height: 100px;
}

.input-area textarea::placeholder {
  color: white;
}

.send-button {
  /*background-color: #e28743;*/
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  height: 50px;
  margin-top: 50px;
}

.send-button:hover {
  background-color: #0955a7;
}

.remove-button,
.home-button,
.logout-button {
  background-color: #494b55;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}

.remove-button:hover, .logout-button:hover, .home-button:hover {
  background-color: #053261;
}

.input-area textarea:active, .input-area textarea:focus, .input-area textarea:hover {
  border: none;
  border-radius: 0px;
  outline: none;
}

@media (max-width: 768px) {
  .sidebar,
  .main-content {
    width: 100%;
    left: 0;
  }

  .sidebar-closed {
    transform: translateX(-100%);
  }

  .main-content {
    margin-left: 0;
  }

  .sidebar-toggle {
    z-index: 6;
  }

  .input-area {
    flex-direction: column;
  }

  .input-area textarea {
    width: 100%;
    margin-bottom: 10px;
  }

  .send-button,
  .home-button,
  .remove-button,
  .logout-button {
    width: 100%;
  }
}
</style>