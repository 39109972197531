<template>
  <div class="header-container">
    <h1>Welkom bij SquadraChat !</h1>
  </div>
  <div class="login-container">
    <div class="input-fields">
    <h3> Login met uw Micosoft account</h3>
    <img class="icon" src="@/assets/chatbot.png"/>
    <p> U wordt naar de officiële inlogpagina van Microsoft gestuurd. Als dit uw eerste keer inloggen is bij SquadraChat, dan moet u bij het inloggen toegang aan de applicatie verlenen.</p>
    <p> Als u geen toegang heeft tot de applicatie, contact dan de admin van deze website. U behoort mogelijk niet tot de organisatie die rechten heeft om SquadraChat te gebruiken.</p>
    <button class="login" @click="login">Login</button>
    </div>
    <div class="popup" v-if="showPopup">
      <p v-if="loginError">{{ loginError }}</p>
      <p>Gebruiker: {{ username}}</p>
      <p>Wachtwoord: [Verborgen]</p>
      <button class="close" @click="closePopup">Probeer opnieuw</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data() {
    return {
      loginError: '',
      showPopup: false // control visibility of the popup
    };
  },
  mounted() {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('code')) {
      this.handleCallback(queryParams.get('code'), queryParams.get('state'));
    }
  },
  methods: {
    async login() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/login`;
      window.location.href = url;
    },
    async handleCallback(code, state) {
      const url = `/login-callback`;
      try {
        if (this.$cookies.get('auth_token')) {
          // Redirect to the chat page if cookie exists
          this.$router.push('/home');
        }
        else {
          const response = await this.$axios.get(`${url}?code=${code}&session_state=${state}`);
          if (response.data.success) {
            this.$cookies.set('auth_token', response.data.token);
            this.$router.push('/home');
          } else {
            this.showPopup = true;
            this.loginError = 'Login failed. Are you a valid member of the Squadra?';
          }
        }
      } catch (error) {
        console.log(error);
        this.showPopup = true;
        this.loginError = 'An unknown error occurred during login, this has probably to do with the authentication system. Please contact the admin.';
      }
    },
    closePopup() {
      this.showPopup = false; // Hide the popup
    }
  }
}
</script>

<style scoped>

.header-container {
  position: fixed; /* Fixed position */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100%; /* Full width */
  background-color: #148cb3; /* Match the background color */
  z-index: 1000; /* Ensure it stays on top of other content */
  text-align: center;
  color: white;
  padding: 10px 0; /* Add some padding */
  height: auto;
}

.login-container {
  display: flex;
  margin-top: 100px;
  align-items: center;
  flex-direction: column;
  background-color: #148cb3;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

h3 {
  text-align: center;
  margin-top: 10px;
}

.input-fields {
  text-align: center;
  padding: 20px;
  border: 2px solid white;
  background-color: white;
  border-radius: 10px;
  max-width: 80vh;
  width: 90%; /* Flexible width */
  overflow-y: auto;
  overflow-x: hidden;
}

p, input, button {
  font-size: 1em; /* Relative to the base font size */
}

body {
  font-size: 16px; /* Base font size */
  overflow-y: auto;
}

h1 {
  text-align: center;
  color: white;
}

.icon {
  width: 75px;
  height: 75px;
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
}

.login {
  font-size: 15px;
  margin-top: 25px;
  width: 35vh;
  height: 5vh;
  background-color: #273D5B;
  color: white;
  border-color: #273D5B;
  border-radius: 10px;
  border-width: 1px;
}

.login:hover {
  cursor:pointer;
  background-color: #3c5e8d;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #701a1a;
  color: white;
  padding: 15px;
  border-radius: 6px;
  z-index: 10;
  width: 50vh;
  max-width: 80%;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  overflow-y: auto; /* Enable vertical scrolling */
}

.popup p {
  text-align: left;
  font-size: 15px;
}

.close {
  text-align: center;
  font-size: 20px;
  margin-top: 25px;
  width: 35vh;
  height: 5vh;
  background-color: #273D5B;
  color: white;
  border-color: #273D5B;
  border-radius: 10px;
  border-width: 1px;
}

.close:hover {
  cursor:pointer;
  background-color: #3c5e8d;
}

@media (max-width: 768px) {
  .input-fields {
    max-width: 80%; /* Adjust maximum width for smaller screens */
  }

  .login, .close {
    width: 70%; /* Adjust button width for smaller screens */
  }

  .popup {
    width: 80%; /* Adjust popup width for smaller screens */
    left: 10%; /* Adjust horizontal position */
    top: 50%; /* Adjust vertical position */
    transform: translate(-50%, -50%); /* Center the popup */
  }
}

/* Optional: Add a background overlay */

</style>