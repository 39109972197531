<template>
  <div class="app-container">
    <aside class="sidebar">
      <div class="sidebar-content">
        <div class="sidebar-section">
          <div class="sidebar-title">Microsoft Copilot</div>
        </div>
        
        <div class="sidebar-section">
          <button @click="goToHome" class="home-button">Home</button>
        </div>
        
        <div class="sidebar-section">
          <button @click="logout" class="logout-button">Logout</button>
        </div>
      </div>
    </aside>
    <div class="content-wrapper">
      <div v-if="error" class="error-container">
        <div class="error-icon">⚠️</div>
        <p class="error-message">{{ error }}</p>
        <button @click="retryConnection" class="retry-button">Probeer opnieuw</button>
      </div>
      
      <div class="iframe-container" v-else-if="iframeUrl">
        <iframe 
          :src="iframeUrl" 
          frameborder="0" 
          allow="microphone; camera"
          class="copilot-iframe">
        </iframe>
      </div>
      
      <div v-else class="loading-container">
        <div class="loading-spinner"></div>
        <!-- <p>Loading Microsoft Copilot...</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopilotPage',
  data() {
    return {
      copilotUrl: process.env.VUE_APP_COPILOT_URL || '',
      iframeUrl: '',
      loading: true,
      error: null
    };
  },
  async created() {
    // Check if user is authenticated
    if (!this.$cookies.get('auth_token')) {
      this.$router.push('/login');
      return;
    }
    
    // Check if the environment variables are set
    if (!this.copilotUrl) {
      console.error('Error: Copilot URL environment variable (VUE_APP_COPILOT_URL) is not set');
      this.error = 'Configuration error: Copilot URL not set';
      return;
    }
    
    if (!process.env.VUE_APP_BEARER_TOKEN) {
      console.error('Error: Bearer token environment variable (VUE_APP_BEARER_TOKEN) is not set');
      this.error = 'Configuration error: Bearer token not set';
      return;
    }
    
    // Load the bot directly - for Teams integration we don't need the DirectLine token
    this.loadBot();
  },
  methods: {
    loadBot() {
      try {
        // For MS Teams bot, just use the direct URL without token
        this.iframeUrl = this.copilotUrl;
        this.loading = false;
        
        // Log success
        console.log("Bot loaded successfully");
        
        // Set up listener for postMessage from iframe
        window.addEventListener('message', this.handleBotMessage);
      } catch (error) {
        console.error('Error loading bot:', error);
        this.error = 'Failed to load Microsoft Copilot';
        this.loading = false;
      }
    },
    
    handleBotMessage(event) {
      // Only process messages from your trusted domain
      if (this.copilotUrl && event.origin.includes(new URL(this.copilotUrl).hostname)) {
        console.log("Received message from bot:", event.data);
        
        // Handle any specific message types here
        if (event.data && event.data.type === 'error') {
          this.error = event.data.message || 'Bot communication error';
        }
      }
    },
    
    retryConnection() {
      this.error = null;
      this.loading = true;
      this.loadBot();
    },
    
    goToHome() {
      this.$router.push('/home');
    },
    
    logout() {
      this.removeSession();
      this.$cookies.remove('auth_token');
      this.$router.push('/login');
    },
    
    async removeSession() {
      const url = `/api/logout`;
      const headers = {
        "Authorization": `Bearer ${process.env.VUE_APP_BEARER_TOKEN}`
      };
      const data = { session_id: this.$cookies.get("auth_token") };
      try {
        const response = await this.$axios.post(url, data, { headers });
        console.log(response.data.success);
      } catch (error) {
        console.error("Error logging out:", error);
      }
    }
  },
  beforeUnmount() {
    // Clean up event listener when component is destroyed
    window.removeEventListener('message', this.handleBotMessage);
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.app-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #0d6881;
}

.sidebar {
  background-color: #0d6881;
  color: #fff;
  width: 300px;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.sidebar-content {
  padding: 60px 25px 25px;
  margin-top: 20px;
}

.sidebar-section {
  margin-bottom: 30px;
}

.sidebar-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.iframe-container {
  width: 65%;
  height: 85vh;
  margin-left: 150px;
}

.copilot-iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.error-container {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  width: 65%;
  margin-left: 150px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.error-icon {
  font-size: 48px;
  margin-bottom: 20px;
}

.error-message {
  color: #e74c3c;
  margin-bottom: 20px;
  font-size: 18px;
}

.retry-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  padding: 50px;
  width: 65%;
  margin-left: 150px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.loading-spinner {
  border: 4px solid rgba(13, 104, 129, 0.3);
  border-radius: 50%;
  border-top: 4px solid #0d6881;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.home-button,
.logout-button {
  background-color: #494b55;
  color: white;
  border: none;
  cursor: pointer;
  padding: 12px 20px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  transition: background-color 0.3s;
}

.home-button:hover,
.logout-button:hover {
  background-color: #053261;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: rgb(80, 80, 80);
  border-radius: 2px;
}

@media (max-width: 1200px) {
  .iframe-container, .error-container, .loading-container {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .app-container {
    display: flex;
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 20;
  }
  
  .sidebar-content {
    padding: 20px;
    margin-top: 0;
  }
  
  .content-wrapper {
    position: relative;
    height: calc(100vh - 200px);
    width: 100%;
    margin-top: 200px;
  }
  
  .iframe-container, .error-container, .loading-container {
    width: 95%;
    height: 100%;
    margin-left: 0;
  }
  
  .home-button,
  .logout-button {
    margin-bottom: 10px;
  }
}
</style>