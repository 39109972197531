<template>
    <div class="header-container">
      <h1>SquadraChat</h1>
    </div>
    <div class="home-container">
      <div class="content-panel">
        <h3>Typ hieronder uw vraag/zoekterm en kies uw assistent naar keuze</h3>
        <img class="icon" src="@/assets/chatbot.png"/>
        
        <p class="description">
          Squadra LLM voor AI-gestuurde antwoorden<br> 
          Microsoft Copilot voor AI-gestuurde antwoorden<br>
          Bing Zoeken voor het vinden van specifieke documenten
        </p>
        
        <div class="search-container">
          <textarea 
            v-model="searchQuery" 
            placeholder="Typ uw vraag of zoekterm hier..."
            @keyup.enter="sendToSquadraLLM"
          ></textarea>
        </div>
        
        <div class="options-container">
          <div class="option-card">
            <h2>Squadra LLM</h2>
            <p>Getraind op Sharepoint data (gemaakt door Machine Learning Company)</p>
            <button class="option-button squadra" @click="sendToSquadraLLM">
              Squadra LLM
            </button>
          </div>
          
          <div class="option-card">
            <h2>Microsoft Copilot</h2>
            <p>Getraind op Sharepoint data (alleen mogelijk als App in Microsoft Teams)</p>
            <button 
              @click="goToCopilot" 
              class="option-button copilot"
            >
              Microsoft Copilot
            </button>
            <!-- <a 
              :href="copilotUrl"
              target="_blank" 
              rel="noopener noreferrer" 
              class="option-button copilot"
            >
              Installeer Copilot
            </a> -->
          </div>
          
          <div class="option-card">
            <h2>Bing Zoeken</h2>
            <p>Zoek door alle mogelijke Sharepoint documenten</p>
            <a 
              :href="bingUrl + searchQuery" 
              target="_blank" 
              rel="noopener noreferrer" 
              class="option-button bing"
            >
              Zoek met Bing
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage',
    data() {
      return {
        searchQuery: '',
        bingUrl: 'https://www.bing.com/work?q='
      }
    },
    methods: {
        sendToSquadraLLM() {
        if (this.searchQuery.trim()) {
          // Using path instead of name for navigation
          this.$router.push({
            path: '/chat',
            query: {
              mode: 'squadra',
              query: this.searchQuery,
              initialChat: 'true'  // Flag to indicate we should immediately send this query
            }
          });
        } else {
          // If no query, just go to ContentPage without query params
          this.$router.push('/chat');
        }
      },
      goToCopilot() {
        this.$router.push('/copilot');
      }
    }
  }
  </script>
  
  <style scoped>
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #148cb3;
  z-index: 1000;
  text-align: center;
  color: white;
  padding: 10px 0;
  height: auto;
}

.home-container {
  display: flex;
  margin-top: 100px;
  align-items: center;
  flex-direction: column;
  background-color: #148cb3;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.content-panel {
  text-align: center;
  padding: 30px;
  border: 2px solid white;
  background-color: white;
  border-radius: 10px;
  max-width: 1200px; /* Increased from 90vh */
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
  box-sizing: border-box;
}

h3 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #333;
  font-size: 1.4em;
}

h2 {
  color: #273D5B;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.icon {
  width: 75px;
  height: 75px;
  object-fit: contain;
  margin-bottom: 20px;
}

.description {
  font-size: 1.1em;
  color: #546e7a;
  margin-bottom: 25px;
  text-align: center;
  line-height: 1.6;
}

.search-container {
  margin-bottom: 25px;
  width: 100%;
  max-width: 800px; /* Added max-width for better readability */
  margin-left: auto;
  margin-right: auto;
}

textarea {
  width: 100%;
  min-height: 80px;
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 10px;
  font-size: 16px;
  resize: none;
  background-color: #f8f9fa;
  transition: border-color 0.3s;
  font-family: 'Tahoma', sans-serif;
  box-sizing: border-box;
}

textarea:focus {
  outline: none;
  border-color: #0c5099;
}

.options-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px; /* Increased gap */
  margin-top: 30px;
  width: 100%;
}

.option-card {
  flex: 1;
  min-width: 250px; /* Increased from 200px */
  max-width: 350px; /* Added max-width */
  padding: 25px; /* Increased padding */
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
}

.option-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.option-card p {
  color: #546e7a;
  margin-bottom: 20px;
  flex-grow: 1;
  line-height: 1.5;
  font-size: 0.95em;
}

.option-button {
  display: inline-block;
  padding: 12px 20px;
  border-radius: 10px;
  border: none;
  font-size: 15px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  text-decoration: none;
  text-align: center;
  width: 100%;
}

.squadra {
  background-color: #074382;
}

.squadra:hover {
  background-color: #147ac9;
}

.copilot {
  background-color: #4c3bac;
}

.copilot:hover {
  background-color: #147ac9;
}

.bing {
  background-color: #112133;
}

.bing:hover {
  background-color: #147ac9;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: rgb(80, 80, 80);
  border-radius: 2px;
}

/* Responsive breakpoints */
@media (min-width: 1600px) {
  /* For very large screens */
  .content-panel {
    max-width: 1400px;
  }
  
  .option-card {
    max-width: 400px;
  }
}

@media (max-width: 1200px) {
  .content-panel {
    max-width: 95%;
  }
}

@media (max-width: 900px) {
  .options-container {
    flex-direction: column;
    align-items: center;
  }
  
  .option-card {
    width: 100%;
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .content-panel {
    max-width: 100%;
    padding: 20px;
  }
  
  textarea {
    width: 100%;
  }
  
  .option-button {
    width: 100%;
  }
  
  h3 {
    font-size: 1.2em;
  }
}
</style>
