import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './pages/LoginPage.vue';
import HomePage from './pages/HomePage.vue';
import ContentPage from './pages/ContentPage.vue';
import VueCookies from 'vue-cookies';
import CopilotPage from './pages/CopilotPage.vue';

const routes = [
  { path: '/login', name: 'LoginPage', component: LoginPage, meta: { requiresAuth: false } },
  { path: '/login-callback', component: LoginPage, meta: { requiresAuth: false } },
  { path: '/chat', name: 'ContentPage', component: ContentPage, meta: { requiresAuth: true } },
  { path: '/home', name: 'HomePage', component: HomePage, meta: { requiresAuth: true } },
  { path: '/copilot', name: 'CopilotPage', component: CopilotPage, meta: { requiresAuth: true } },
  { path: '/', redirect: '/login' }, // Redirect to /login by default
  { path: '', redirect: '/login' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

const isAuthenticated = () => {
  return !!VueCookies.get('auth_token');
};

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    if (isAuthenticated() && to.path === '/login') {
      next({ path: '/home' });
    } else {
      next();
    }
  }
});

export default router;
